.decisionStatsScreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.clickableAreas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.victimImage {
  width: 50%;
  height: 100%;
  left: 0;
}

.trolleyImage {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
}
