.splashScreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.splashScreenImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clickableAreas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.trolleyArea {
  position: absolute;
  top: 30%;
  left: 10%;
  width: 51%;
  height: 55%;
  cursor: not-allowed;
}

.cowArea {
  position: absolute;
  top: 45%;
  left: 62%;
  width: 30%;
  height: 40%;
  cursor: crosshair;
}
