.introScreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.introScreenImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
