.sentenceVictimScreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.sentencedVictimImage {
  width: 100%;
  height: 100%;
  left: 0;
}
